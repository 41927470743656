.tictactoeContent {
    box-sizing: border-box;
    box-shadow: 5px 5px;
    background-color: rgba(200, 200, 200, 0.7);
    padding: 20px;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >.tictactoeBoard{
        display: flex;
        flex-direction: column;

        >.tictactoeRow{
            display: flex;

            >.tictactoeCell{
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid black;
            }
        }   
    }

    >.tictactoeHistoryMenu {
        margin-top: 12px;
        display: flex;
        align-items: center;
        >button {
            margin: 0px 6px;
            padding: 6px;
        }
    }
}