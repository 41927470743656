@import '../../../variables';

.landingHatchDefinitionContent {
    display: flex;
    justify-content: space-between;
    padding: 60px 0;

    @media screen and (max-width: $mobile) {
        flex-direction: column;
        padding: 40px 12px;
    }

    >.left {
        @media screen and (max-width: $mobile) {
            padding-bottom: 20px;
        }   
    }

    >.right {
        padding-left: 50px;

        @media screen and (max-width: $mobile) {
            padding: 0px;
        }
    }
}

.line {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &.firstLine {
        > h2 {
            padding-right: 16px;
            font-family: 'Righteous';
            font-size: 56px;
            margin: 0;
        }
        > p {
            font-size: 18px;
            margin: 0;
            padding-right: 12px;
        }
        > div {
            width: 24px;
            height: 24px;
            > img {
                width: 100%;
            }
        }
    }

    &.verbLine {
        font-size: 14px;
    }

    &.definitionLine {
        font-size: 20px;
        font-style: italic;
        font-weight: 400;
    }

    &.cambridgeLine {
        font-size: 20px;
        text-align: end;
        justify-content: flex-end;

        > div {
            width: 50px;
            margin-right: 16px;
            border: 1px solid black;

            @media screen and (max-width: $mobile) {
                display: none;
            }
        }
    }
}