@import '../../../../variables.scss';

.lobbyContainer {
    margin: 0;
    background-image: linear-gradient($primary-dark, $primary);
    height: calc(100vh - 60px);

    display: flex;
    justify-content: center;
    align-items: center;

    >.lobbyContent {
        box-sizing: border-box;
        box-shadow: 5px 5px;
        background-color: rgba(200, 200, 200, 0.7);
        padding: 20px;
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (max-width: $mobile) {
            max-width: 80%;
        }

        h2 {
            margin: 0;
            margin-bottom: 20px;
            align-self: center;
        }

        >.lobbyCloseButton {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;

            font-size: 24px;
        }
    }
}