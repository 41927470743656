@import '../../../../variables.scss';

.projectsContainer {
    margin: 0;
    background-image: linear-gradient($primary-dark, $primary);
    height: calc(100vh - 60px);

    display: flex;
    justify-content: center;
    align-items: center;

    .projectsContent {
        box-sizing: border-box;
        box-shadow: 5px 5px;
        background-color: rgba(200, 200, 200, 0.7);
        padding: 20px;

        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
            margin: 0;
            margin-bottom: 20px;
            align-self: center;
        }

        .projectsCards {
            display: flex;
            justify-content: center;

            @media screen and (max-width: $mobile) {
                flex-direction: column;
                height: 350px;
            }

            .projectsCard {
                margin: 0px 16px;
                justify-content: flex-end;
                background-color: wheat;

                @media screen and (max-width: $mobile) {
                    margin: 16px 0px;
                }
            }
        }
    }
}