@import '../../../../../../variables.scss';

.roomContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    max-height: 50vh;
    overflow-y: auto;

    @media screen and (max-width: $mobile) {
        max-height: 300px;
        overflow-x: hidden;
    }

    >.room {
        display: flex;
        border: 1px solid black;
        margin-bottom: 8px;

        @media screen and (min-width: $mobile) {
            width: 500px;
        }

        >.roomInfo {
            display: flex;
            justify-content: space-between;

            width: inherit;
            padding: 16px;
        }

        >.roomButton {
            display: flex;
            justify-content: center;
            align-items: center;

            border-left: 1px solid black;
            padding: 20px;

            cursor: pointer;
            
        }
        
        >.roomButton:hover {
            background-color: greenyellow;
        }
    }
}