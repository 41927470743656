$content-size: 1110px;

$primary: #2B912D;
$primary-dark: #2B6535;
$primary-light: #9FD39C;

$secondary: #0AC9B0;
$secondary-dark: #2B918A;

$background-section: #eeffee;

$text-color: #f0f8ff;
$text-color-black: black;

$gray: #CDCDCD;

$mobile: 1100px;