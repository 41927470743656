@import '../../../variables.scss';

.gameContainer {
    margin: 0;
    background-image: linear-gradient($primary-dark, $primary);
    height: calc(100vh - 60px);

    > .roomInfo {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        box-sizing: border-box;
        padding: 0px 8px;
        height: 30px;

        .announcer {
            color: white;
            font-weight: 800;
        }

        > .mobileAnnouncer {
            display: flex;
            justify-content: center;
        }

        > .actions {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            >.najjarDocButton {
                cursor: pointer;
            }
        }
    }

    > .gameContent {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100% - 30px);

        > .prepRoomContent {
            box-sizing: border-box;
            box-shadow: 5px 5px;
            background-color: rgba(200, 200, 200, 0.7);
            padding: 20px;
            position: relative;
    
            display: flex;
            flex-direction: column;
            justify-content: center;
    
            @media screen and (max-width: $mobile) {
                max-width: 80%;
            }
        }
    }
}