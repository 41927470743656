@import '../../../variables';


.landingFeaturesContainer {
    .landingFeaturesContent {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        background-color: $primary;
        padding: 40px;
        box-shadow: 5px 5px;
        margin: 30px 0px;
    
        @media screen and (max-width: $mobile) {
            flex-direction: column;
            padding: 0px 12px;
            min-height: 50vh;
            justify-content: space-around;
        }
    
        >.left {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $text-color;
    
            font-size: 50px;

            @media screen and (max-width: $mobile) {
                padding: 12px 0px;
                font-size: 40px;
            }
        }
    
        >.right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: $text-color;
            width: 600px;
    
            @media screen and (max-width: $mobile) {
                padding: 0px;
                width: inherit;
                text-align: center;
            }
    
            >.text {
                font-size: 24px;
                margin-bottom: 30px;
                text-align: center;
    
                @media screen and (max-width: $mobile) {
                    font-size: 18px;
                    margin-bottom: 20px;
                }
            }

            >.button-container {
                display: flex;
                justify-content: space-evenly;
                width: 100%;
                
                @media screen and (max-width: $mobile) {
                    flex-direction: column;

                    >button {
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }
}