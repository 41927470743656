@import '../../../../variables.scss';

.chatContainer {
    position: fixed;
    bottom: 0px;
    left: 8px;
    transition: all 1s;

    &.open {
        transition: all 1s;
        bottom: 8px;
    }

    @media screen and (max-width: $mobile) {
        right: 8px
    }

    >.chatOpenerButton {
        width: fit-content;
        cursor: pointer;
        padding: 6px;
        position: relative;
        background-color: rgb(0, 102, 0);
        border: 2px solid black;
        border-bottom: 0px;
        border-radius: 12px 12px 0 0;
        color: $text-color;
        &.open {
            bottom: -2px
        }
        &:hover {
            background-color: rgb(0, 90, 0); 
        }
    }

    >.chatWrapper {
        background-color: rgb(0, 102, 0);
        color: $text-color;
        max-height: 0px;
        transition: max-height 1s;
        overflow: hidden;
        
        &.open {
            border: 2px solid black;
            border-radius: 0 12px 12px 12px;
            padding: 8px;
            max-height: 278px;
            transition: max-height 1s;
        }

        >.messageContainer{
            height: 160px;
            overflow-y: auto;
            display: flex;
            flex-direction: column-reverse;
            >.message {
                p {
                    
                    margin: 8px 0px;
                }
            }
        }

        >.activeChannelButton {
            padding: 8px;
        }

        >.messageActiveChannel {
            margin: 0;
        }
    
        >.chatForm {
            display: flex;
            margin-top: 8px;

            >.sendInput {
                width: -webkit-fill-available;
            }

            >.sendButton {
                padding: 8px;
            }
        }
    }

}