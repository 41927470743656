.card-game {
    width: 200px;
    height: 250px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .logo {
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 2;
    }

    .text {
        z-index: 2;
        font-size: 18px;
        text-align: center;
        padding: 12px;
        background-color: rgba(0, 0, 0, 0.4);
        font-weight: 700;
    }

    &:hover {
        .text, .logo {
            background-color: rgba(0, 0, 0, 0.7);
        }
    }
}