@import '../../variables';

.najjarPrototype {
    @media screen and (max-width: $mobile) {
        >.najjarProjectSectionContent {
            >.left {
                font-size: 34px;
            }
        }
    }
}

.najjarDoc {
    .najjarDocButton {
        cursor: pointer;
        margin-top: 15px;
        margin-bottom: 20px;
    }
}