@import '../../variables';

.footer-container {
    background-color: $primary-dark;
    display: flex;
    justify-content: center;

    .content {
        width: $content-size;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0px;

        @media screen and (max-width: $mobile) {
            width: 95%;
        }

        .title {
            font-family: 'Righteous';
            text-decoration: none;
            color: $text-color; 
            font-size: 40px;
            line-height: 40px;
            user-select: none;
        }

        .right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .text {
                color: $text-color;

                @media screen and (max-width: $mobile) {
                    font-size: 14px;
                }
            }

            .button {
                margin-right: 20px;
                &:last-child {
                    margin-right: 0px;
                }
                text-decoration: none;
                color: $text-color;
                font-weight: 800;
            }
        }
    }
}