@import './variables';

html, body, .viewport {
    width: 100%;
    height: 100%;
    margin: 0;
    box-sizing: border-box;
}

* {
    font-family: 'Kufam';
    font-weight: 500;
}

*::selection {
    color: $primary-dark;
    background: $secondary;
}

#root {
    height: 100%;
}

.container {
    height: 100%;
    display: flex;
}

.onlyMobile {
    display: none !important;
}

@media screen and (max-width: $mobile) {
    .onlyMobile {
        display: inherit !important
    }

    .onlyDesktop {
        display: none !important
    }   
}